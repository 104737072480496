<template>
  <div class="fill-height d-flex flex-column">
    <h3 class="mb-3">{{ widget.title }}</h3>
    <div class="widget_container">
      <component :is="componentIs()" :widget="widget" />
    </div>
  </div>
</template>

<script>
import WidgetChartBar from "./charts/WidgetChartBar.vue";
import WidgetChartPie from "./charts/WidgetChartPie.vue";

export default {
  components: { WidgetChartBar, WidgetChartPie },
  props: {
    widget: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    componentIs() {
      switch (this.widget.settings?.chart_type) {
        case "bar":
          return "WidgetChartBar";
        case "pie":
          return "WidgetChartPie";
        default:
          return "WidgetChartBar";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.widget_container {
  width: 100%;
  height: 75%;
  max-height: 75%;
  position: relative;
}
</style>
