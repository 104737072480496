<template>
  <flat-pickr
    v-model="date"
    :config="config"
    @on-change="onChange"
    @on-month-change="$emit('onMonthChange')"
  />
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import { Russian } from "flatpickr/dist/l10n/ru.js";
import { english } from "flatpickr/dist/l10n/default.js";

import i18n from "@/locale";

const LOCALES = { en: english, ru: Russian };

export default {
  components: {
    flatPickr,
  },
  props: {
    value: [Date, String],
    inline: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Date,
      default: null,
    },
    max: {
      type: Date,
      default: null,
    },
  },
  emits: ["onChange", "input", "onMonthChange"],
  data() {
    return {
      date: this.value,
      config: {
        inline: this.inline,
        locale: LOCALES[i18n.locale],
        maxDate: this.max,
        minDate: this.min,
        ariaDateFormat: "J.m.Y",
        nextArrow: `<svg width="9" height="14" viewBox="0 0 9 14">
<path d="M0.354179 12.3544L5.53178 7.17678L5.70855 7L5.53178 6.82322L0.354181 1.64563L1.65604 0.35293L8.30311 7L1.65604 13.6471L0.354179 12.3544Z" fill="#4F4F4F" fill-opacity="0.5" stroke="white" stroke-width="0.5"/>
</svg>

`,
        prevArrow: `<svg width="9" height="14" viewBox="0 0 9 14">
<path d="M8.30244 1.64563L3.12484 6.82322L2.94806 7L3.12484 7.17678L8.30243 12.3544L7.00057 13.6471L0.353502 7L7.00057 0.352929L8.30244 1.64563Z" fill="#4F4F4F" fill-opacity="0.5" stroke="white" stroke-width="0.5"/>
</svg>
`,
      },
    };
  },
  watch: {
    date() {
      this.$emit("input", this.date);
    },
  },
  methods: {
    onChange(selectedDates, dateStr, instance) {
      this.$emit("onChange", { selectedDates, dateStr, instance });
    },
  },
};
</script>

<style lang="scss">
:root {
  --color-calendar: #4f4f4f;
}

.flatpickr-calendar.inline {
  top: 0;
  border-radius: 0;
  min-width: 384px;
  padding: 5px;
}

.flatpickr-rContainer,
.flatpickr-days,
.dayContainer {
  max-width: inherit;
  width: 100%;
}

.flatpickr-weekday,
.flatpickr-day {
  width: calc(100% / 7) !important;
  max-width: calc(100% / 7) !important;
}

.flatpickr-input[readonly] {
  display: none;
}

.flatpickr-day.today {
  background: #343957 !important;
  font-size: 14px;
  border-radius: 50% !important;
  color: white !important;
  max-width: 40px !important;
}

.flatpickr-next-month,
.flatpickr-prev-month {
  top: 10px !important;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: rgba(107, 81, 223, 0.2);
  border-radius: 0;
  -webkit-box-shadow: none;

  box-shadow: none;
  color: var(--color-calendar);
  border-color: rgba(107, 81, 223, 0.2);
}

.flatpickr-weekdaycontainer > .flatpickr-weekday:nth-child(7n),
.flatpickr-weekdaycontainer > .flatpickr-weekday:nth-child(7n-1) {
  color: rgba(107, 81, 223, 0.7);
}

.dayContainer > .flatpickr-day:nth-child(7n),
.dayContainer > .flatpickr-day:nth-child(7n-1) {
  color: #6b51df;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  border-radius: 0;
  background: rgba(107, 81, 223, 0.2);

  transition: all 0.4s ease-out;
}

.flatpickr-current-month {
}

.flatpickr-current-month > span.cur-month,
.flatpickr-current-month > .numInputWrapper {
  font-weight: 400;
  padding: 0;
  font-size: 20px;
  color: var(--color-calendar);
}

li .widget-calendar-link {
  color: white;
  text-decoration: none;
}

.widget-calendar-no-tasks {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 -50% 0 0;
  transform: translate(-50%, -50%);
}

.flatpickr-months {
  margin: 10px 0;
}

.flatpickr-day {
  border-radius: 0;
  width: 48px;

  color: var(--color-calendar);

  position: relative;
  --visabilite: 0;

  &::before {
    content: "";
    width: 4px;
    height: 4px;
    background: #6b51df;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: var(--visabilite);
  }
}

.flatpickr-current-month input.cur-year {
  font-weight: 400;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  color: var(--color-calendar);
  font-weight: 400;
  font-size: 20px;
  text-align: right;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  -moz-appearance: none;
  -webkit-appearance: none;
}
</style>
