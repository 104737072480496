<template>
  <div>
    <h3 class="mb-3">{{ widget.title }}</h3>
    <v-data-table
      v-if="items.length"
      :headers="getHeaders"
      :items="items"
      :itemsPerPage="5"
      height="100%"
    >
      <template
        v-for="(header, i) in widget.settings.headers"
        v-slot:[`item.${header}`]="{ item }"
      >
        <component
          :is="componentIs(header)"
          :key="i"
          :item="item"
          :header="header"
          :formatter="getFormatter(header, item)"
          :fields="fields"
        />
      </template>
    </v-data-table>
    <span v-else>
      {{ $t("ui_nodata") }}
    </span>
  </div>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";

import {
  getParentFieldName,
  isCustomField,
} from "@/services/formService/fields";
import TreeService from "@/services/treeService";
import { Autoreload } from "@/utils/autoreload";
import formatLocale from "@/utils/formaters/formatLocale";
import tableCellsFormatter from "./scripts/tableCellsFormatter";
import TableCellBase from "./tableCells/TableCellBase.vue";
import TableCellKey from "./tableCells/TableCellKey.vue";
import TableCellStatus from "./tableCells/TableCellStatus.vue";
import TableCellUser from "./tableCells/TableCellUser.vue";

export default {
  components: {
    TableCellBase,
    TableCellKey,
    TableCellStatus,
    TableCellUser,
  },
  props: {
    widget: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapState("core$common", ["node", "fields"]),
    getHeaders() {
      if (!this.widget?.settings?.headers) return [];

      return this.widget.settings.headers.map((header) => ({
        text: this.getHeader(header),
        value: header,
        sortable:
          this.widget.settings.sortable &&
          this.widget.settings.sortable.includes(header),
      }));
    },
  },
  methods: {
    getHeader(header) {
      const parentHeader = isCustomField(header)
        ? getParentFieldName(header)
        : header;

      const text = this.widget?.settings?.text;
      if (text && text[header]) return text[header];

      const field = this.fields.find((f) => f.name === parentHeader);

      if (!field) return "";

      return formatLocale(field.title);
    },
    componentIs(header) {
      switch (header) {
        case "key":
          return "TableCellKey";
        case "status":
          return "TableCellStatus";
        case "assignee":
        case "author":
          return "TableCellUser";
        default:
          return "TableCellBase";
      }
    },
    getFormatter(header, item) {
      if (tableCellsFormatter[header]) {
        return () => tableCellsFormatter[header](item);
      }
    },
    request() {
      if (!this.widget.query?.request) return;

      api
        .request({
          name: "getTasksByRQL",
          pathParam: this.node.id,
          payload: {
            rql: this.widget.query.request,
            fields: this.widget.query.fields,
            sort_type: this.widget.query.sort_type,
          },
        })
        .then(({ data }) => {
          this.items = data.data.map((item) => {
            return TreeService.treeFromDictToDictFlat(item);
          });
        });
    },
  },
  created() {
    this.request();
    this._autoreload = new Autoreload(this.request.bind(this));
    this._autoreload.init();
  },
  beforeDestroy() {
    this._autoreload.destroy();
  },
};
</script>

<style lang="scss" scoped></style>
