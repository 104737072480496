const REGEX =
  /(?<key>\S+)\s(?<comparisonOperator>=|!=|>|>=|<|<=)?\s(?<value>\S+)(\s(?<logicalOperator>\S+))?/g;
const IS_METHOD_REGEX = /\(.*\)/;

class RQLService {
  constructor({ query, regex = REGEX }) {
    const results = query.matchAll(regex);
    this.params = [];
    for (let result of results) {
      result.groups.value = result.groups.value.replace(/"|'/g, "");
      this.params.push(result.groups);
    }
  }

  update(args) {
    const { key, comparisonOperator, value, logicalOperator } = args;
    if (!key || !value) return;

    const index = this.params.findIndex((p) => p.key === key);
    if (index < 0) return;

    const params = this.params[index];
    params.value = value;
    if (comparisonOperator) params.comparisonOperator = comparisonOperator;
    if (logicalOperator) params.logicalOperator = comparisonOperator;
  }

  get query() {
    let result = "";
    this.params.forEach((p) => {
      const valueIsMethod = IS_METHOD_REGEX.test(p.value);
      const _value = valueIsMethod ? p.value : `'${p.value}'`;
      result += `${p.key} ${p.comparisonOperator} ${_value} ${
        p.logicalOperator || ""
      } `;
    });
    return result.trim();
  }
}

export { RQLService };
