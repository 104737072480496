import { debounce } from "./debounce";

export const useResize = ({ querySelector, cb }) => {
  if (!querySelector) return console.log("[useResize] requires querySelector");
  if (!cb) return console.log("[useResize] requires callback method");

  let element;
  let resizeObserver;
  const _debounce = debounce(cb, 1500);

  return {
    mount() {
      element = document.querySelector(querySelector);
      if (!element) return console.log("[useResize] requires querySelector");

      resizeObserver = new ResizeObserver(() => _debounce("from debounce"));
      resizeObserver.observe(element);
    },
    unMount() {
      resizeObserver.unobserve(element);
    },
  };
};
