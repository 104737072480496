let coords = {
  x: 0,
  y: 0,
};

export const disableScroll = function (wrapperSelector) {
  if (!wrapperSelector)
    return console.log("[disableScroll] element target is required");
  const el = document.querySelector(wrapperSelector);
  coords.y = el.scrollTop;
  coords.x = el.scrollLeft;

  el.style.overflow = "hidden";
  // el.scrollTop = coords.y;
  // el.scrollLeft = coords.x;
};

export const enableScroll = function (wrapperSelector) {
  if (!wrapperSelector)
    return console.log("[disableScroll] element target is required");
  const el = document.querySelector(wrapperSelector);

  el.style.overflow = "auto";
};
