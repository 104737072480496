<template>
  <portal to="header">
    <div class="d-flex">
      <v-icon
        class="ml-6"
        :color="isDraggable ? 'accent' : 'primary'"
        @click="onDragClicked"
      >
        mdi-drag
      </v-icon>
    </div>
  </portal>
</template>

<script>
export default {
  data() {
    return {
      isDraggable: false,
    };
  },
  methods: {
    onDragClicked() {
      this.isDraggable = !this.isDraggable;
      this.$emit("onDragClicked", this.isDraggable);
    },
  },
};
</script>

<style scoped lang="scss"></style>
