import {
  CUSTOM_FIELDS_PREFIX,
  isCustomField,
} from "@/services/formService/fields";
import { formatIfDate } from "@/utils/date/index";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    header: {
      type: String,
      default: "",
    },
    formatter: {
      type: Function,
    },
    fields: {
      type: Array,
      default: [],
    },
  },
  computed: {
    getCell() {
      if (this.formatter) {
        return this.formatter(this.item);
      }

      if (isCustomField(this.header)) {
        const fullName = `${CUSTOM_FIELDS_PREFIX}.${this.header}`;
        const f = this.fields.find((f) => f.name === this.header);
        if (f && f.type === "select") {
          const opt = f.options.find((o) => o.value === this.item[fullName]);
          return opt?.title || this.item[fullName];
        }
        return formatIfDate(this.item[fullName]);
      }

      return this.item[this.header];
    },
  },
};
