<template>
  <div class="calendarfull-daily">
    <div class="calendarfull-daily-header">
      <div
        class="calendarfull-daily-arrows calendarfull-daily-arrows-left"
        :class="{ disabled: !isToLeftEnabled }"
        @click="scrollTo(false)"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </div>
      <div ref="$userWrapper" class="calendarfull-user-wrapper">
        <div :style="gridContainerStyle">
          <WidgetCalendarFullLegend
            v-for="(el, i) in xData"
            :key="i"
            :title="el.title"
            :subtitle="el.subtitle"
            :style="perItemStyle"
          />
        </div>
      </div>
      <div
        class="calendarfull-daily-arrows calendarfull-daily-arrows-right"
        :class="{ disabled: !isToRightEnabled }"
        @click="scrollTo(true)"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </div>
    </div>

    <div class="calendarfull-daily-inner">
      <div
        class="calendarfull-daily-inner-timeline calendarfull-daily-inner-timeline-left"
      >
        <div
          v-for="i in 24"
          :key="i"
          class="calendarfull-daily-inner-timeline-item"
        >
          <div>
            <span> {{ getYTime(i) }} </span>
          </div>
        </div>
      </div>
      <div ref="$innerWrapper" class="calendarfull-daily-inner-container">
        <div class="calendarfull-daily-inner-subcontainer">
          <div
            :style="gridContainerStyle"
            class="calendarfull-daily-inner-trans-container"
          >
            <div
              v-for="i in xLine"
              :key="i"
              class="calendarfull-daily-inner-x"
              :style="perItemStyle"
            >
              <div v-for="k in 24" :key="k" class="calendarfull-daily-inner-y">
                <hr />
              </div>
            </div>
            <div class="calendarfull-daily-cards">
              <template v-for="(el, i) in xData">
                <template v-for="(card, k) in el.coords">
                  <div
                    v-if="card"
                    :key="i + '_' + k"
                    class="calendarfull-daily-card-wrapper"
                    :style="{
                      width: card.w + 'px',
                      height: card.h + 'px',
                      top: card.t + 'px',
                      left: card.l + 'px',
                      'z-index': 10 + k,
                    }"
                    @click="$emit('cardClicked', el.data[k])"
                  >
                    <WidgetCalendarFullCard
                      :header="el.cards[k].header"
                      :title="el.cards[k].title"
                      :fields="el.cards[k].fields"
                      :color="el.cards[k].color"
                    />
                  </div>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div
        class="calendarfull-daily-inner-timeline calendarfull-daily-inner-timeline-right"
      >
        <div
          v-for="i in 24"
          :key="i"
          class="calendarfull-daily-inner-timeline-item"
        >
          <div>
            <span> {{ getYTime(i) }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WidgetCalendarFullCard from "../WidgetCalendarFullCard/WidgetCalendarFullCard.vue";
import WidgetCalendarFullLegend from "../WidgetCalendarFullLegend/WidgetCalendarFullLegend.vue";

const MIN_ITEM_WIDTH_PX = 256;
const MIN_ITEM_HEIGHT_PX = 32;
const HOUR_HEIGHT_PX = MIN_ITEM_HEIGHT_PX * 2;
const MINUTE_HEIGHT_PX = Math.round(MIN_ITEM_HEIGHT_PX / 60);
// const INNER_SELECTOR = ".calendarfull-daily-inner-trans-container";
const OUTER_SELECTOR = ".calendarfull-daily-inner-subcontainer";

export default {
  components: { WidgetCalendarFullCard, WidgetCalendarFullLegend },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    xType: {
      type: String,
      default: "simple",
    },
    yType: {
      type: String,
      default: "timeline",
    },
    widget: {
      type: Object,
      default: () => ({}),
    },
    fieldSettings: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      innerWidth: 0,
      outerWidth: 0,
      itemWidth: MIN_ITEM_WIDTH_PX,
      translateX: 0,
    };
  },
  computed: {
    fromLeft() {
      return Math.abs(this.translateX);
    },
    isToRightEnabled() {
      return this.fromLeft < this.innerWidth - this.outerWidth;
    },
    isToLeftEnabled() {
      return this.fromLeft > 0;
    },
    gridContainerStyle() {
      return { transform: `translateX(${this.translateX}px)` };
    },
    perItemStyle() {
      return {
        "max-width": `${this.itemWidth}px`,
        "min-width": `${this.itemWidth}px`,
      };
    },
    ////////////////////////////
    legendHedaers() {
      return this.widget?.settings?.axis?.axis_x?.legend?.headers || {};
    },
    axisXField() {
      return this.widget?.settings?.axis?.axis_x?.fields[0] || "";
    },
    axisYField() {
      return this.widget?.settings?.axis?.axis_y?.field || "";
    },
    axisXType() {
      return this.widget?.settings?.axis?.axis_x?.type || "simple";
    },
    cardFields() {
      return this.widget?.settings?.view?.card?.card_fields || [];
    },
    cardColor() {
      return this.widget?.settings?.view?.card?.card_color;
    },
    ////////////////////////////
    xData() {
      if (!this.axisYField) return [];

      const result = [];
      for (let i = 0; i < this.data.length; i++) {
        const task = this.data[i];
        const key = task[this.axisXField] || "all"; // значение поля, по кот. проиводится группировка колонок

        let catchedIndex = null;
        result.forEach((col, i) => {
          if (col.key === key) catchedIndex = i;
        });

        if (catchedIndex === null) {
          const { title, subtitle } = this.getLegend({
            task,
            legendHedaers: this.legendHedaers,
          });
          result.push({
            key,
            title,
            subtitle,
            data: [task],
            cards: [this.getTaskCardFields({ task })],
            coords: [
              this.getTaskCoords({
                task,
                axisYField: this.axisYField,
                i: result.length,
              }),
            ],
          });
        } else {
          result[catchedIndex].data.push(task);
          result[catchedIndex].cards.push(this.getTaskCardFields({ task }));
          result[catchedIndex].coords.push(
            this.getTaskCoords({
              task,
              axisYField: this.axisYField,
              i: catchedIndex,
            })
          );
        }
      }
      return result;
    },
    xLine() {
      return this.xData.length || 1;
    },
  },
  methods: {
    getYTime(i = 0) {
      const hours = i < 11 ? `0${i - 1}` : i - 1;
      return hours + ":00";
    },
    getLegend({ task, legendHedaers }) {
      if (!task) return { title: "", subtitle: "" };

      const legendFields = legendHedaers.fields || [this.axisXField];
      const legendDelimiter = legendHedaers.delimiter || "";
      const legendSubtitle = legendHedaers.subtitle || "";

      let result = "";
      legendFields.forEach((field, i) => {
        const fieldValue = task[field] || "";
        result += fieldValue;
        if (i !== legendFields.length - 1 && legendDelimiter) {
          result += `${legendDelimiter} `;
        }
      });

      return {
        title: result,
        subtitle: task[legendSubtitle],
      };
    },
    scrollTo(right = true) {
      if (right) {
        if (!this.isToRightEnabled) return;
        if (
          this.fromLeft >=
          this.innerWidth - this.outerWidth - this.itemWidth
        ) {
          this.translateX = -(this.innerWidth - this.outerWidth);
        } else {
          this.translateX -= this.itemWidth;
        }
      } else {
        if (!this.isToLeftEnabled) return;
        if (this.fromLeft < this.itemWidth) {
          this.translateX = 0;
        } else {
          this.translateX += this.itemWidth;
        }
      }
    },
    getTaskCoords({ task, axisYField, i = 0 }) {
      if (!task || !axisYField)
        return console.error(
          "getTaskCoords require args [task, axisXField, axisXType]"
        );

      const value = task[axisYField];
      if (!value) return null;

      const reg = /\d\d:\d\d/g;
      const res = value.match(reg);
      if (res.length < 2)
        return console.error("getTaskCoords wrong datetime format");

      const from = res[0];
      const to = res[1];
      const fromHours = from.slice(0, 2);
      const fromMinutes = from.slice(3);
      const toHours = to.slice(0, 2);
      const toMinutes = to.slice(3);

      const top =
        Number(fromHours) * HOUR_HEIGHT_PX +
        Number(fromMinutes) * MINUTE_HEIGHT_PX;
      const height =
        Number(toHours) * HOUR_HEIGHT_PX +
        Number(toMinutes) * MINUTE_HEIGHT_PX -
        top;

      return {
        w: this.itemWidth,
        h: height,
        l: this.itemWidth * i,
        t: top,
      };
    },
    getTaskCardFields({ task }) {
      const header = task[this.axisYField] || task.key;
      const title =
        this.cardFields.length > 0 && task[this.cardFields[0]]
          ? task[this.cardFields[0]]
          : task.title;

      const result = {
        header,
        title,
        fields: [],
        color: this.cardColor,
      };

      if (this.cardFields.length > 1) {
        result.fields = this.cardFields.slice(1).map((f) => task[f]);
      }

      return result;
    },
    draw() {
      this.outerWidth = document
        .querySelector(OUTER_SELECTOR)
        .getBoundingClientRect().width;
      const xDataLength = this.xData.length || 1;
      const perItemPx = this.outerWidth / xDataLength;
      this.itemWidth =
        perItemPx <= MIN_ITEM_WIDTH_PX ? MIN_ITEM_WIDTH_PX : perItemPx;
      this.innerWidth = this.itemWidth * xDataLength;
    },
  },
  mounted() {
    this.draw();
  },
  watch: {
    xData: {
      handler(value) {
        if (!value || !value.length) return;
        this.draw();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss"></style>
