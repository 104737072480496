<template>
  <v-btn
    :to="{
      name: 'TaskCard',
      params: { processKey: item['process.key'], taskKey: item.key },
    }"
    text
    color="accent"
  >
    {{ getCell }}
  </v-btn>
</template>

<script>
import tableCellsMixin from "./tableCellsMixin";

export default {
  mixins: [tableCellsMixin],
};
</script>

<style lang="scss" scoped></style>
