<template>
  <div class="d-flex align-center">
    <em class="dot mr-2" :style="{ 'background-color': getColor }"></em>
    {{ getCell }}
  </div>
</template>

<script>
import tableCellsMixin from "./tableCellsMixin";
import getStatusColor from "@/utils/formaters/getStatusColor";

export default {
  mixins: [tableCellsMixin],
  computed: {
    getColor() {
      return getStatusColor(this.getCell);
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  white-space: nowrap;
  em {
    opacity: 0.8;
  }
}
</style>
