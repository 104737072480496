<template>
  <span> {{ getCell }} </span>
</template>

<script>
import tableCellsMixin from "./tableCellsMixin";

export default {
  mixins: [tableCellsMixin],
};
</script>

<style lang="scss" scoped></style>
