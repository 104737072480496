const colors = {
  "К выполнению": "#343a57ab",
  Новая: "#343a57ab",
  "В процессе": "#FFE178",
  "В работе": "#FFE178",
  Готово: "#299817",
  Завершено: "#299817",
};

export default (status) => colors[status] || "#343a57ab";
