<template>
  <canvas :id="id" width="1000" height="500"></canvas>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
// import { Autoreload } from "@/utils/autoreload";

import {
  ArcElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  PieController,
  Title,
  Tooltip,
} from "chart.js";

Chart.register(
  ArcElement,
  PieController,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip
);

function formatter(items) {
  const result = {};

  items.forEach((item) => {
    const issuetypeName = item.process.name;

    if (!result[issuetypeName]) {
      result[issuetypeName] = 1;
    } else {
      result[issuetypeName] = result[issuetypeName] + 1;
    }
  });

  return result;
}

const config = {
  type: "pie",
  data: {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: ["rgba(108, 81, 223, 1)", "#ffd269"],
      },
    ],
  },
  options: {
    maintainAspectRatio: false,
    responsive: true,
  },
};

export default {
  props: {
    widget: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      id: "ch_" + Date.now(),
      config: Object.assign({}, config),
    };
  },
  computed: {
    ...mapState("core$common", ["node"]),
  },
  methods: {
    async request() {
      if (!this.widget.query?.request) return;

      await api
        .request({
          name: "getTasksByRQL",
          pathParam: this.node.id,
          payload: {
            rql: this.widget.query.request,
            fields: this.widget.query.fields,
            sort_type: this.widget.query.sort_type,
          },
        })
        .then(({ data }) => {
          const result = formatter(data.data);
          this.config.data.labels = Object.keys(result);
          this.config.data.datasets[0].data = Object.values(result);
          this.initChart();
        });
    },
    initChart() {
      this._ctx = document.getElementById(this.id).getContext("2d");
      this._chart = new Chart(this._ctx, config);
    },
  },
  created() {
    this.request();

    // this._autoreload = new Autoreload(this.request.bind(this));
    // this._autoreload.init();
  },
  // beforeDestroy() {
  //   this._autoreload.destroy();
  // },
};
</script>

<style lang="scss" scoped></style>
