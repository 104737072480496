const DEFAULT_INTERVAL = 1000 * 10;

export class Autoreload {
  constructor(callback, interval = DEFAULT_INTERVAL) {
    if (!callback) return console.error("autoreload requires callback");

    this.callback = callback;
    this.interval = interval;
  }

  init() {
    if (this._interval) return console.error("interval object exists");
    this._interval = setInterval(this.callback, this.interval);
  }

  destroy() {
    clearInterval(this._interval);
    this._interval = null;
  }
}
