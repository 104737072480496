<template>
  <div class="dropdown-wrapper" @click="open">
    <slot></slot>
    <div v-show="opened" class="dropdown-container" @click.stop="close">
      <div class="dropdown-content" :style="style">
        <div
          v-for="(option, i) in options"
          :key="i"
          class="dropdown-option"
          @click="$emit('optionClick', { option, i })"
        >
          <div v-if="option.icon" class="dropdown-option-icon">
            <v-icon>{{ option.icon }}</v-icon>
          </div>
          <div v-if="option.text" class="dropdown-option-text">
            {{ option.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      opened: false,
      style: {
        left: 0,
        top: 0,
      },
    };
  },
  methods: {
    open(e) {
      const { clientX, clientY } = e;
      this.style.left = clientX + "px";
      this.style.top = clientY + "px";

      this.opened = true;
    },
    close() {
      this.opened = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-wrapper {
  .dropdown-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    .dropdown-content {
      position: fixed;
      z-index: 10;
      background: #fff;
      box-shadow: 0px 10px 20px rgba(230, 230, 230, 0.25);
      border-radius: 4px;
      overflow: hidden;

      .dropdown-option {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 12px;
        font-size: 0.875rem;
        color: initial;
        cursor: pointer;
        min-width: 184px;
        max-width: 400px;
        &:hover {
          background: #656880;
          color: #fff;
        }
        .dropdown-option-icon {
          svg {
            width: 16px;
            height: 16px;
            min-width: 16px;
            min-height: 16px;
            margin-right: 20px;
            color: inherit;
          }
        }
        .dropdown-option-text {
        }
      }
    }
  }
}
</style>
