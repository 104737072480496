<template>
  <div class="fill-height widget widget-calendarfull-wrapper-base">
    <div :id="id" class="widget widget-calendarfull-wrapper">
      <h3 v-if="widget.title" class="mb-3">
        {{ widget.title || "Календарь" }}
      </h3>
      <AppDatePicker
        v-if="date"
        v-model="date"
        appendIcon="mdi-calendar"
        solo
        dense
        flat
        :clearable="false"
        class="widget-calendarfull-datepicker"
        @change="onDateChange"
      />
      <div class="calendarfull-container" :class="{ loading }">
        <component
          :is="componentIs"
          ref="$widget"
          :fieldSettings="fieldSettings"
          :data="data"
          :widget="widget"
          :loading="loading"
          @cardClicked="cardClicked"
        ></component>
      </div>
    </div>
    <WidgetCardModal
      ref="$WidgetCardModal"
      :fieldSettings="fieldSettings"
      :title="widget?.settings?.view?.sidebar?.title || 'задача'"
      :subtitle="`Дата создания: ${modalCardSubtitle}`"
      :fields="
        widget?.settings?.view?.sidebar?.sidebar_fields || [
          'title',
          'description',
        ]
      "
      :data="taskSelected"
      wrapperSelector=".widget-calendarfull-wrapper-base"
      @sidebarBtnClick="sidebarBtnClick"
    />
  </div>
</template>

<script>
import api from "@/api";
import { dataService } from "@/services/dataService/index";
import { RQLService } from "@/services/rqlService";
import { format } from "@/utils/date";
import formatLocale from "@/utils/formaters/formatLocale";
import { useResize } from "@/utils/handlers/resize";
import { mapGetters, mapState } from "vuex";
import WidgetCardModal from "../components/WidgetCardModal/WidgetCardModal.vue";
import WidgetCalendarFullDaily from "./components/WidgetCalendarFullDaily/WidgetCalendarFullDaily.vue";

export default {
  components: { WidgetCalendarFullDaily, WidgetCardModal },
  props: {
    widget: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      data: [],
      taskSelected: {},
      id: "calendar_" + Date.now(),
      fieldSettings: {},
      date: format(new Date(), "yyyy-MM-dd"),
      loading: false,
    };
  },
  computed: {
    ...mapState("core$common", ["node", "statuses", "statuses", "fields"]),
    ...mapGetters("core$common", ["getStatusesByProcessKey"]),
    modalCardSubtitle() {
      if (!this.taskSelected.created_at) return "";
      return format(this.taskSelected.created_at);
    },
    componentIs() {
      switch (this.widget.type) {
        case "schedule":
          return "WidgetCalendarFullDaily";
        default:
          return "WidgetCalendarFullDaily";
      }
    },
    navigationField() {
      return this.widget?.settings?.axis?.navigation?.field || "";
    },
  },
  methods: {
    onDateChange() {
      this._RQLService.update({
        key: this.navigationField,
        value: this.date,
        comparisonOperator: "=",
      });
      this.request().then(() => this.$refs.$widget.draw());
    },
    request() {
      if (!this.widget.query?.request) return;

      this.loading = true;

      return api
        .request({
          name: "getTasksByRQL",
          pathParam: this.node.id,
          payload: {
            rql: this._RQLService.query,
            fields: this.widget.query.fields,
            sort_type: this.widget.query.sort_type,
          },
        })
        .then(({ data }) => {
          this.data = dataService.deserializeFloatArray({ data: data.data });
          if (!this.data.length || Object.keys(this.fieldSettings).length) {
            return;
          }
          this.fieldSettings = dataService.getFieldsSettings({
            fields: this.fields,
            statuses: this.getStatusesByProcessKey(this.data[0]["process.key"]),
            users: this.users,
            editableFields: [],
            visibleFields: [],
            reeadonly: false,
            alias: this.widget.settings?.text || {},
            isFlatStructure: true,
            localeMethod: formatLocale,
          });
        })
        .finally(() => (this.loading = false));
    },
    cardClicked(data) {
      this.taskSelected = data;
      this.$refs.$WidgetCardModal.open();
    },
    sidebarBtnClick(data) {
      this.$router.push({
        name: "TaskCard",
        params: { processKey: data.process.key, taskKey: data.key },
      });
    },
  },
  created() {
    this._RQLService = new RQLService({ query: this.widget.query.request });
    this.onDateChange();
    this.request();
  },
  mounted() {
    this._resizeHandler = useResize({
      querySelector: `#${this.id}`,
      cb: this.$refs.$widget.draw,
    });
    this._resizeHandler.mount();
  },
  beforeDestroy() {
    this._resizeHandler.unMount();
    this._resizeHandler = null;
  },
};
</script>

<style lang="scss"></style>
