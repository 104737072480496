import { treeCollectStructure } from "./utils";

const SYSTEM_OBJECT_FIELDS = ["assignee", "status", "author", "process"];
const GRID_COUNT = 12;
const CUSTOM_FIELD_PREFIX = "cf_";
const CUSTOM_FIELDS_PREFIX = "custom_fields";
const OPTION_TEXT_FIELD = "optionText";
const OPTION_VALUE_FIELD = "optionValue";
const OPTIONS_FIELD = "options";
const DEFAULT_DATEPICKER_ICON = "calendar";
const SYSTEM_FIELDS_STRUCTURES = {
  assignee: {
    id: {
      type: "text",
    },
    user_name: {
      type: "text",
    },
    "fio.name": {
      type: "text",
    },
    "fio.surname": {
      type: "text",
    },
    "fio.middlename": {
      type: "text",
    },
    "contacts.email": {
      type: "email",
    },
    "contacts.phone": {
      type: "phone",
    },
  },
  author: {
    id: {
      type: "text",
    },
    user_name: {
      type: "text",
    },
    "fio.name": {
      type: "text",
    },
    "fio.surname": {
      type: "text",
    },
    "fio.middlename": {
      type: "text",
    },
    "contacts.email": {
      type: "email",
    },
    "contacts.phone": {
      type: "phone",
    },
  },
  status: {
    id: {
      type: "text",
    },
    name: {
      type: "text",
    },
    description: {
      type: "text",
    },
  },
  process: {
    id: {
      type: "text",
    },
    key: {
      type: "text",
    },
    name: {
      type: "text",
    },
    description: {
      type: "text",
    },
  },
};

/**
 * @param {array} fields поля формы
 * @return {object} возвращает плоскую структуру формы без значений
 */
const getFormDataStructure = (fields) => {
  const result = {};

  fields.forEach((field) => {
    const value = field.structure ? treeCollectStructure(field.structure) : "";
    result[field.name] = value;
  });

  return result;
};

const getTitle = ({ alias, field, localeMethod }) => {
  if (!alias) {
    return localeMethod ? localeMethod(field.title) : field.title;
  }
  const preDefinedtext = alias[field.name];
  if (preDefinedtext) return preDefinedtext;

  return localeMethod ? localeMethod(field.title) : field.title;
};

const getIcon = ({ iconName, field }) => {
  if (field.type === "datepicker") return iconName || DEFAULT_DATEPICKER_ICON;
  return iconName || null;
};

const getSettings = ({ users, statuses, field }) => {
  if (field.type !== "select") return field.settings;

  const fieldSettings = field.settings || {};
  const setting = Object.assign({}, fieldSettings);
  switch (field.name) {
    case "status":
      setting[OPTIONS_FIELD] = statuses;
      setting[OPTION_TEXT_FIELD] = "name";
      setting[OPTION_VALUE_FIELD] = "id";
      break;
    case "author":
    case "assignee":
      setting[OPTIONS_FIELD] = users;
      setting[OPTION_TEXT_FIELD] = "full_name";
      setting[OPTION_VALUE_FIELD] = "id";
      break;
  }

  return setting;
};

const getPlaceholder = ({ text, field, localeMethod }) => {
  if (!field.placeholder) return getTitle({ text, field, localeMethod });

  return localeMethod ? localeMethod(field.placeholder) : field.placeholder;
};

const getSystemFieldStruct = (fieldName) => SYSTEM_FIELDS_STRUCTURES[fieldName];

const isCustomField = (fieldName) => {
  return fieldName.startsWith(CUSTOM_FIELD_PREFIX);
};

const isFieldReadonly = ({ fieldName, editableFields }) => {
  if (
    !editableFields ||
    (Array.isArray(editableFields) && !editableFields.length)
  )
    return false;
  return !editableFields.includes(fieldName);
};

const isFieldVisible = ({ fieldName, visibleFields }) => {
  if (!visibleFields || !visibleFields.length) return true;

  return visibleFields.includes(fieldName);
};

const isSystemObjectField = (fieldName) =>
  SYSTEM_OBJECT_FIELDS.includes(fieldName);

export {
  CUSTOM_FIELDS_PREFIX,
  GRID_COUNT,
  SYSTEM_OBJECT_FIELDS,
  getFormDataStructure,
  getIcon,
  getPlaceholder,
  getSettings,
  getSystemFieldStruct,
  getTitle,
  isCustomField,
  isFieldReadonly,
  isFieldVisible,
  isSystemObjectField,
};
