<template>
  <div class="fill-height" @click="onYmapsClick">
    <div :id="id" class="map-container"></div>
    <Dropdawn
      ref="dropdawn"
      :options="clusterItems"
      @optionClick="optionClick"
    />

    <Modal ref="modal" width="400px" position="right">
      <div v-if="item.id" class="sidebar-wrapper">
        <div class="d-flex justify-space-between mb-10">
          <div class="font-weight-black">{{ item.process.name }}</div>
          <div :style="{ color: statusClass }" class="status-text">
            {{ item.status.name }}
          </div>
        </div>
        <div class="mb-15">
          <div
            v-for="(field, i) in widget.settings.sidebar_fields"
            :key="i"
            class="d-flex mb-6 sidebar-fields"
          >
            <div>{{ getFieldTitle(field) }}</div>
            <div>{{ getFieldValue(field) }}</div>
          </div>
        </div>
        <div class="sidebar-description mb-10">
          {{ item.description }}
        </div>
        <router-link
          tag="a"
          class="accent--text font-weight-medium"
          :to="{
            name: 'TaskCard',
            params: { processKey: item.process.key, taskKey: item.key },
          }"
          target="_blank"
        >
          {{ $t("ui_show_more") }}
        </router-link>
      </div>
    </Modal>
  </div>
</template>

<script>
import api from "@/api";
import ymaps from "ymaps";

import Dropdawn from "@/components/Dropdawn/Dropdawn.vue";
import { widgetEmitter } from "@/components/widgets/widgetBus";
import TreeService from "@/services/treeService";
import { format } from "@/utils/date";
import getStatusColor from "@/utils/formaters/getStatusColor";
import { mapState } from "vuex";
import { YANDEX_MAP_TOKEN } from "../../../../env/.env.js";

const YMAP_SRC = `https://api-maps.yandex.ru/2.1/?apikey=${YANDEX_MAP_TOKEN}&lang=ru_RU`;
const CLUSTER_ICONS = [
  {
    href: require("@/assets/icons/cluster.svg"),
    size: [40, 40],
    offset: [-20, -20],
  },
];
const MAX_ZOOM = 21;

export default {
  components: { Dropdawn },
  props: {
    widget: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      items: [],
      item: {},
      id: "map_" + Date.now(),
      clusterItems: [],
      e: {},
    };
  },
  computed: {
    ...mapState("core$common", ["node"]),
    statusClass() {
      return getStatusColor(this.item.status.name);
    },
    widgetTitles() {
      return this.widget?.settings?.text || {};
    },
    addressFieldName() {
      return this.widget?.settings?.address_field;
    },
    mapDefaultCoords() {
      const center = this.widget?.settings?.center || [55.8, 37.6];
      const zoom = this.widget?.settings?.zoom || 7;

      return {
        controls: [],
        center,
        zoom,
      };
    },
    addressField() {
      if (!this.addressFieldName || !this.item["id"]) return;

      return this.item.custom_fields[this.addressFieldName];
    },
  },
  methods: {
    optionClick({ option }) {
      this.item = this.items.find((item) => item.key === option.key);
      this.$refs.modal.show();
      this.$refs.dropdawn.close();
    },
    request() {
      if (!this.widget.query?.request) return;

      return api
        .request({
          name: "getTasksByRQL",
          pathParam: this.node.id,
          payload: {
            rql: this.widget.query.request,
            fields: this.widget.query.fields,
            sort_type: this.widget.query.sort_type,
          },
        })
        .then(({ data }) => {
          if (!this.addressFieldName) return;

          this.items = data.data.filter((item) => {
            const field = item.custom_fields[this.addressFieldName];
            return !!field && field.lat && field.lon;
          });
        });
    },
    ymapInit() {
      return ymaps
        .load(YMAP_SRC)
        .then((maps) => {
          this._mapInstanse = maps;
          this._mapElement = new maps.Map(this.id, this.mapDefaultCoords);
        })
        .catch((error) => console.log("Failed to load Yandex Maps", error));
    },
    addPoints() {
      if (!this.items) return;

      const MyIconContentLayout =
        this._mapInstanse.templateLayoutFactory.createClass(
          '<div style="color: #6B51DF; font-weight: bold;">{{ properties.geoObjects.length }}</div>'
        );
      const claster = new this._mapInstanse.Clusterer({
        hasBalloon: false,
        hasHint: false,
        clusterIcons: CLUSTER_ICONS,
        clusterIconContentLayout: MyIconContentLayout,
      });

      this.items.forEach((item, i) => {
        const { lat, lon } = item.custom_fields[this.addressFieldName];
        let [_lat, _lon] = [Number(lat), Number(lon)];

        const placemark = new this._mapInstanse.Placemark(
          [_lat, _lon],
          {},
          {
            iconLayout: "default#image",
            iconImageHref: require("@/assets/icons/map-point.svg"),
            hintContent: item.title,
          }
        );

        placemark.events.add("click", () => {
          this.item = this.items[i];
          this.$refs.modal.show();
        });

        claster.add(placemark);
      });

      claster.events.add("click", this.onClusterClick.bind(this));
      this._mapElement.geoObjects.add(claster);
    },
    onYmapsClick(e) {
      this.e = { clientX: e.offsetX, clientY: e.offsetY };
    },
    async onClusterClick(e) {
      await this.$nextTick();
      this.clusterItems = [];
      const zoom = this._mapElement.getZoom();
      if (zoom === MAX_ZOOM) {
        const coords = e.get("coords");
        const lat = coords[0].toFixed(3);
        const lon = coords[1].toFixed(3);

        this.items.forEach((item) => {
          const f = item.custom_fields[this.addressFieldName];
          const [_lat, _lon] = [f.lat, f.lon];
          if (!_lat || !_lon) return false;

          if (
            lat === Number(_lat).toFixed(3) &&
            lon === Number(_lon).toFixed(3)
          ) {
            this.clusterItems.push({
              text: `(${item.key}) - ${item.title}`,
              key: item.key,
            });
          }
        });

        if (this.clusterItems.length > 1) {
          setTimeout(() => {
            this.$refs.dropdawn.open(this.e);
          }, 0);
        }
      }
    },
    calculateSize() {
      this._mapElement.container.fitToViewport();
      if (this.widget?.settings?.center) return;
      this._mapElement.setBounds(this._mapElement.geoObjects.getBounds(), {
        checkZoomRange: true,
        zoomMargin: 35,
      });
    },
    layoutIsChanged() {
      this.calculateSize();
    },
    getFieldValue(f) {
      // FIX THIS SHIT
      if (f === "status" || f === "author" || f === "assignee") {
        const field = this.item[f];
        return field ? field["user_name"] : "";
      }

      if (this.item[f]) {
        if (f === "created_at") {
          return format(this.item[f]);
        }
        return this.item[f];
      }

      const pathSplited = f.split(".");
      return TreeService.treeGetDictFlatValue(
        { [pathSplited[0]]: this.item.custom_fields[pathSplited[0]] },
        f
      );
    },
    getFieldTitle(field) {
      return this.widgetTitles[field] || field;
    },
  },
  async mounted() {
    const f1 = this.ymapInit();
    const f2 = this.request();
    Promise.all([f1, f2]).then(() => {
      this.addPoints();
      this.calculateSize();
    });
  },
  created() {
    widgetEmitter.on("layoutIsChanged", this.layoutIsChanged);
  },
  beforeDestroy() {
    this._mapElement.destroy();
    this._mapElement = null;
    this._mapInstanse = null;
  },
};
</script>

<style lang="scss" scoped>
.map-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.sidebar-wrapper {
  height: 100%;
  overflow: auto;
  .sidebar-fields {
    & > div {
      &:nth-of-type(1) {
        width: 40%;
      }
      &:nth-of-type(2) {
        width: 60%;
      }
    }
  }
  .sidebar-description {
    color: #373737;
  }
}

.status-text {
  font-weight: 600;
}
</style>
