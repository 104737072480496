import {
  CUSTOM_FIELDS_PREFIX,
  GRID_COUNT,
  getFormDataStructure,
  getIcon,
  getPlaceholder,
  getSettings,
  getSystemFieldStruct,
  getTitle,
  isCustomField,
  isFieldReadonly,
  isFieldVisible,
  isSystemObjectField,
} from "./fields";
import {
  isPrimitive,
  treeCollectStructure,
  treeFromDictFlatToDict,
  treeFromDictToDictFlat,
  treeFromTreeArrayToDict,
  treeFromTreeArrayToDictFlat,
  treeGetDictFlatValue,
  treeGetFieldByIndex,
} from "./utils";
import { getValidation } from "./validation";

const dataService = {
  deserializeForm({ data, fields }) {
    const formDataStructure = getFormDataStructure(fields);
    if (!data) return formDataStructure;

    const result = {};

    for (let field in formDataStructure) {
      if (isSystemObjectField(field)) {
        result[field] = data[field]?.id || null;
      } else if (!isCustomField(field)) {
        result[field] = data[field];
      } else if (isPrimitive(formDataStructure[field])) {
        result[field] = data[CUSTOM_FIELDS_PREFIX][field] || "";
      } else {
        const cf = {};
        for (let subField in formDataStructure[field]) {
          cf[subField] = treeGetDictFlatValue(
            data[CUSTOM_FIELDS_PREFIX][field],
            subField
          );
        }
        result[field] = cf;
      }
    }

    return result;
  },

  deserializeFloat({ data }) {
    const dataStructure = treeFromDictToDictFlat(data);
    if (!data) return dataStructure;

    const result = {};
    for (let field in dataStructure) {
      const parentField = treeGetFieldByIndex(field, 0);
      if (parentField === CUSTOM_FIELDS_PREFIX) {
        const key = CUSTOM_FIELDS_PREFIX + ".";
        result[field.replace(key, "")] = dataStructure[field];
      } else {
        result[field] = dataStructure[field];
      }
    }

    result.assignee = data?.assignee?.id;
    result.author = data?.author?.id;
    result.process = data?.process?.id;
    result.status = data?.status?.id;

    return result;
  },

  deserializeFloatArray({ data }) {
    return data.map((d) => this.deserializeFloat({ data: d }));
  },

  serializeForm(data) {
    const result = {};
    const customFields = {};

    for (let fieldName in data) {
      if (isSystemObjectField(fieldName)) {
        result[fieldName + "_id"] = data[fieldName];
      } else if (isCustomField(fieldName)) {
        const value = isPrimitive(data[fieldName])
          ? data[fieldName]
          : treeFromDictFlatToDict(data[fieldName]);

        customFields[fieldName] = value;
      } else {
        result[fieldName] = data[fieldName];
      }
    }

    result[CUSTOM_FIELDS_PREFIX] = customFields;

    return result;
  },

  getFieldsSettings({
    fields,
    statuses,
    users,
    editableFields,
    visibleFields,
    localeMethod,
    alias = {},
    grid = {},
    icons = {},
    isFlatStructure = false,
  }) {
    const result = {};

    fields.forEach((field) => {
      result[field.name] = {
        type: field.type,
        label: getTitle({ alias, field, localeMethod }),
        placeholder: getPlaceholder({ alias, field, localeMethod }),
        appendIcon: getIcon({ iconName: icons[field.name], field }),
        grid: grid[field.name] || GRID_COUNT,
        default: field.default || null,
        readonly: isFieldReadonly({
          fieldName: field.name,
          editableFields,
        }),
        visible: isFieldVisible({
          fieldName: field.name,
          visibleFields,
        }),
        settings: getSettings({ users, statuses, field }),
        childFields: null,
      };

      const _isSystemObjectField = isSystemObjectField(field.name);
      if (field.structure || _isSystemObjectField) {
        result[field.name].childFields = {};
        const structure = _isSystemObjectField
          ? getSystemFieldStruct(field.name)
          : field.structure;

        for (let subFieldName in structure) {
          const subFieldWithParentName = `${field.name}.${subFieldName}`;
          const subField = structure[subFieldName];
          const childFieldSettings = {
            type: subField.type,
            label: alias[subFieldWithParentName] || subFieldName,
            placeholder: alias[subFieldWithParentName] || subFieldName,
            appendIcon: getIcon({
              iconName: icons[subFieldWithParentName],
              field,
            }),
            grid: grid[subFieldWithParentName] || GRID_COUNT,
            settings: getSettings({ users, statuses, field: subField }),
            default: subField.default || null,
            readonly: isFieldReadonly({
              fieldName: subFieldWithParentName,
              editableFields,
            }),
            visible: isFieldVisible({
              fieldName: field.name,
              visibleFields,
            }),
            childFields: null,
          };

          if (isFlatStructure) {
            result[`${field.name}.${subFieldName}`] = childFieldSettings;
          } else {
            result[field.name].childFields[subFieldName] = childFieldSettings;
          }
        }
      }
    });

    return result;
  },

  getValidation,
  isPrimitive,
  treeCollectStructure,
  treeFromDictFlatToDict,
  treeFromDictToDictFlat,
  treeFromTreeArrayToDict,
  treeFromTreeArrayToDictFlat,
  treeGetDictFlatValue,
  treeGetFieldByIndex,
};

export { dataService };
