<template>
  <div>
    <Grids
      v-if="board && board.settings && board.settings.layout"
      ref="grid"
      :layout="board.settings.layout"
      :isDraggable="isDraggable"
    >
      <template #default="{ index }">
        <WidgetWrapper
          :pluginConfigId="getPluginConfigId(index)"
          :nodeId="node.id"
        >
          <component :is="componentIs(index)" :widget="board.widgets[index]" />
        </WidgetWrapper>
      </template>
    </Grids>

    <PortalHeaderGridControlls
      v-if="showGridControlls"
      @onDragClicked="onDragClicked"
    />
  </div>
</template>

<script>
import api from "@/api";

import { mapGetters, mapState } from "vuex";

import Grids from "@/components/Grids/Grids.vue";
import WidgetCalendar from "@/components/widgets/WidgetCalendar/WidgetCalendar.vue";
import WidgetCalendarFull from "@/components/widgets/WidgetCalendarFull/WidgetCalendarFull.vue";
import WidgetChart from "@/components/widgets/WidgetChart/WidgetChart.vue";
import WidgetMap from "@/components/widgets/WidgetMap/WidgetMap.vue";
import WidgetTable from "@/components/widgets/WidgetTable/WidgetTable.vue";
import WidgetWrapper from "@/components/widgets/WidgetWrapper/WidgetWrapper.vue";
import { rights } from "@/data/rights";

import PortalHeaderGridControlls from "@/components/portals/PortalHeaderGridControlls.vue";
import { widgetEmitter } from "@/components/widgets/widgetBus";

export default {
  components: {
    Grids,
    WidgetTable,
    WidgetChart,
    WidgetWrapper,
    WidgetCalendar,
    WidgetMap,
    WidgetCalendarFull,
    PortalHeaderGridControlls,
  },
  data() {
    return {
      board: {},
      isDraggable: false,
    };
  },
  computed: {
    ...mapState("core$common", ["boards", "node"]),
    ...mapGetters("core$rights", ["hasRight"]),
    showGridControlls() {
      return this.hasRight(rights.LayoutEdit);
    },
  },
  methods: {
    componentIs(index) {
      const widgets = this.board.widgets;
      if (index > widgets.length - 1) return "";

      switch (widgets[index].settings.type) {
        case "table":
          return "WidgetTable";
        case "chart":
          return "WidgetChart";
        case "calendar":
          return "WidgetCalendar";
        case "calendar_full":
          return "WidgetCalendarFull";
        case "map":
          return "WidgetMap";
        default:
          return "";
      }
    },
    layoutIsChanged() {
      const payload = Object.assign({}, this.board.settings);

      api.request({
        name: "editBoard",
        pathParam: { nodeId: this.node.id, boardId: this.board.id },
        payload: { settings: payload },
        showAlert: true,
      });
    },
    getPluginConfigId(index) {
      const widgets = this.board.widgets;
      if (index > widgets.length - 1) return "";

      const widget = widgets[index];
      if (widget.settings.type !== "plugin") return "";

      return widget.settings.plugin_config_id;
    },
    onDragClicked(e) {
      this.isDraggable = e;
      if (e) return;

      this.layoutIsChanged();
      widgetEmitter.emit("layoutIsChanged");
    },
  },
  created() {
    this.board = this.boards.find(
      (board) => board.name === this.$route.params.board
    );
    if (!this.board) {
      this.$alert({ text: this.$t("board_does_not_extsts"), type: "warning" });
    }
  },
};
</script>

<style lang="scss"></style>
