<script>
export default {
  props: {
    header: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    fields: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: "",
    },
  },
  computed: {
    cardStyle() {
      if (!this.color) return;

      return { background: this.color };
    },
  },
};
</script>

<template>
  <div class="calendarfull-daily-card" :style="cardStyle">
    <h4 v-if="header" class="calendarfull-daily-card-header">{{ header }}</h4>
    <h3 v-if="title" class="calendarfull-daily-card-title">{{ title }}</h3>
    <p
      v-for="(field, i) in fields"
      :key="i"
      class="calendarfull-daily-card-text"
    >
      {{ field }}
    </p>
  </div>
</template>
