//  Утилита для работы с деревьями. Структурами типа ["f1.f2.f4", "f3"]

/*
Метод сериализует массив {tree} вида:
["field1", "field1.field2", "field3"]
В структуру вида:
{ field1: { field2: "" }, field3: "" }
Можно завернуть результат в объект с ключом {key}
*/
const treeFromTreeArrayToDict = function (tree, key) {
  if (!tree || typeof tree !== "object")
    return console.error("tree must be an array");

  const res = {};
  for (let i = 0; i < tree.length; i++) {
    const fields = tree[i];
    const fieldsSplited = fields.split(".");

    if (fieldsSplited.length === 1) {
      res[fields] = "";
      continue;
    }

    let objectLink = null;
    for (let k = 0; k < fieldsSplited.length; k++) {
      const field = fieldsSplited[k];
      if (k === 0) {
        res[field] = {};
        objectLink = res[field];
      } else if (k < fieldsSplited.length - 1) {
        objectLink[field] = {};
        objectLink = objectLink[field];
      } else {
        objectLink[field] = "";
      }
    }
  }

  const result = key ? { [key]: res } : res;

  return result;
};

/*
Метод сериализует массив {tree} вида:
["field1.field2", "field3"]
В плоскую структуру вида:
{ "field1.field2": "", "field3": "" }
*/
const treeFromTreeArrayToDictFlat = function (tree) {
  if (!tree || typeof tree !== "object")
    return console.error("tree must be an array");

  const result = {};
  tree.forEach((el) => (result[el] = ""));

  return result;
};

/*
Метод сериализует объект {tree} вида:
{ "f1.f2": "value" }
В структуру вида:
{ f1: { f2: "value" }}
*/
const treeFromDictFlatToDict = function (tree) {
  if (typeof tree !== "object") return console.error("tree must be an object");

  const result = {};
  for (let field in tree) {
    const fieldsSplited = field.split(".");

    if (fieldsSplited.length === 1) {
      result[field] = tree[field];
      continue;
    }

    let objectLink = null;
    for (let i = 0; i < fieldsSplited.length; i++) {
      const _field = fieldsSplited[i];
      if (i === 0) {
        if (!result[_field]) result[_field] = {};
        objectLink = result[_field];
      } else if (i < fieldsSplited.length - 1) {
        if (!objectLink[_field]) objectLink[_field] = {};
        objectLink = objectLink[_field];
      } else {
        objectLink[_field] = tree[field];
      }
    }
  }

  return result;
};

/*
Метод сериализует объект {object} вида:
{ "f1": { "f2": "value" }, "f3": "value" }
В структуру вида:
{ "f1.f2": "value", "f3": "value" }}
*/

const treeFromDictToDictFlat = function (object, keys, result) {
  const _result = result || {};

  for (let key in object) {
    let _value = object[key];
    let _keys = keys ? `${keys}.${key}` : key;

    if (typeof _value !== "object") {
      _result[_keys] = _value;
      continue;
    }

    treeFromDictToDictFlat(_value, _keys, _result);
  }

  return _result;
};

/*
Метод возвращает значение по пути {path} вида "f1.f2.f3" в объекте {object} вида:
{ "f1": { f2: { f3: "value" }}}
*/
const treeGetDictFlatValue = function (object, path) {
  if (!object) return "";

  const pathSplited = path.split(".");

  let result = null;
  try {
    for (let i = 0; i < pathSplited.length; i++) {
      const subField = pathSplited[i];
      if (i === 0) {
        result = object[subField];
      } else {
        result = result[subField];
      }
    }
  } catch {
    return "";
  }

  return result;
};

/*
Метод сериализует объект {tree} вида:
{ "f1.f2": {
  type,
  options,
  default
}}
В структуру вида:
{ "f1.f2": "" || default}
*/

const treeCollectStructure = function (structure) {
  const result = {};

  for (let field in structure) {
    result[field] = structure[field].default || "";
  }

  return result;
};

const treeGetFieldByIndex = function (field, index) {
  if (!field) return "";
  const splited = field.split(".");

  if (splited.length <= index) return field;
  return splited[index];
};

const isPrimitive = (v) => {
  if (v === null) return true;
  return typeof v !== "function" && typeof v !== "object";
};

export {
  isPrimitive,
  treeCollectStructure,
  treeFromDictFlatToDict,
  treeFromDictToDictFlat,
  treeFromTreeArrayToDict,
  treeFromTreeArrayToDictFlat,
  treeGetDictFlatValue,
  treeGetFieldByIndex,
};
