<template>
  <transition name="from-right">
    <div
      v-if="show"
      class="modal-wrapper widget-card-sidebar-wrapper"
      @click.self="close"
    >
      <div class="modal-container">
        <div class="modal-content">
          <v-icon class="modal-close-btn" @click="close">mdi-close</v-icon>
          <!-- <Icon name="x" class="modal-close-btn" @click="close" /> -->
          <div class="widget-card-inner">
            <h4 v-if="title" class="widget-card-title">{{ title }}</h4>
            <h5 v-if="subtitle" class="widget-card-subtitle">{{ subtitle }}</h5>
            <!-- <div v-if="status" class="widget-card-status">{{ status }}</div> -->
            <!-- <div v-for="(fieldName, i) in fields" :key="i">
              <p>{{ fieldSettings[fieldName]?.label || fieldName }}</p>
              <p>{{ data[fieldName] }}</p>
            </div> -->
            <AppFieldCreator
              v-for="(fieldName, i) in fields"
              :key="i"
              v-model.trim="data[fieldName]"
              :label="fieldSettings[fieldName]?.label || fieldName"
              :items="fieldSettings[fieldName]?.settings?.options"
              :type="fieldSettings[fieldName]?.type"
              :itemText="fieldSettings[fieldName]?.settings?.optionText"
              :itemValue="fieldSettings[fieldName]?.settings?.optionValue"
              readonly
              :fieldName="fieldName"
              :fieldSettings="fieldSettings[fieldName]?.childFields"
              class="mt-7"
              hide-details="auto"
              dense
              flat
              disabled
            />
          </div>
          <router-link
            tag="a"
            class="accent--text font-weight-medium task-details-btn"
            :to="{
              name: 'TaskCard',
              params: { processKey: data['process.key'], taskKey: data.key },
            }"
            target="_blank"
          >
            {{ $t("ui_show_more") }}
          </router-link>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { disableScroll, enableScroll } from "@/utils/scrollLock/scrollLock";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    fields: {
      type: Array,
      default: () => [],
    },
    fieldSettings: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    wrapperSelector: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    open() {
      disableScroll(this.wrapperSelector);
      this.show = true;
    },
    close() {
      enableScroll(this.wrapperSelector);
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
