<template>
  <div class="containerFlatPicker">
    <DataPicker
      v-model="date"
      inline
      @onMonthChange="showMarker"
      @onChange="onChange"
    />
    <div class="containerDate">
      <p class="weekDay">
        {{ weekDay }}
      </p>
      <p class="dayMonth">{{ day }} {{ month }}</p>

      <ul v-if="sidebarTaskList.length" class="containerTask">
        <li
          v-for="task in sidebarTaskList"
          :key="task.key"
          class="widget-calendar-link"
          @click="goTaskRoute(task)"
        >
          {{ getFieldValue(task, titleField) }}
        </li>
      </ul>
      <p v-else class="widget-calendar-no-tasks">
        {{ $t("noTask", { thisDay: day, thisMonth: month }) }}
      </p>
    </div>
  </div>
</template>

<script>
import "flatpickr/dist/flatpickr.css";

import api from "@/api";
import DataPicker from "@/components/fields/components/AppDatePickerNew/AppDatePickerNew";

import {
  CUSTOM_FIELDS_PREFIX,
  isCustomField,
} from "@/services/formService/fields";
import TreeService from "@/services/treeService";
import { getWeekDay } from "@/utils/date";
import { mapState } from "vuex";

export default {
  components: {
    DataPicker,
  },
  props: {
    widget: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      date: new Date(),
      day: "",
      weekDay: this.$t("today"),
      month: "",
      items: [],
      firstRender: true,
    };
  },
  computed: {
    ...mapState("core$common", ["node", "fields"]),
    dateFieldName() {
      // Отрисовывать точки на карте по этому полю
      return this.widget?.settings?.date_field_name || "created_at";
    },
    titleField() {
      // Отрисовывать список на сайдбаре по этому полю
      return this.widget?.settings?.title_field_name || "title";
    },
    sidebarTaskList() {
      return this.items.filter((item) => {
        const field = this.getFieldValue(item, this.dateFieldName);

        return (
          new Date(field).toLocaleDateString() ===
          new Date(this.date).toLocaleDateString()
        );
      });
    },
    pointDateList() {
      //
      return [
        ...new Set(
          this.items.map((item) => {
            const field = this.getFieldValue(item, this.dateFieldName);

            return new Date(field).toLocaleDateString();
          })
        ),
      ];
    },
  },
  methods: {
    getFieldValue(task, f) {
      if (f === "status" || f === "author" || f === "assignee") {
        return task[f].id;
      }

      const fieldName = isCustomField(f) ? `${CUSTOM_FIELDS_PREFIX}.${f}` : f;
      return task[fieldName] || "";
    },
    getTitle(task) {
      return isCustomField(this.titleField)
        ? task.custom_fields[this.titleField]
        : task[this.titleField];
    },
    showMarker: function () {
      this.pointDateList.forEach((item) => {
        const newDate = item[0] === "0" ? item.slice(1) : item;
        document
          .querySelector(`.flatpickr-day[aria-label="` + newDate + '"]')
          ?.style.setProperty("--visabilite", 1);
      });
    },

    goTaskRoute: function (task) {
      let routeData = this.$router.resolve({
        name: "TaskCard",
        params: { processKey: task["process.key"], taskKey: task.key },
      });
      window.open(routeData.href, "_blank");
    },

    onChange: function (getSelectedDates) {
      const selectedDates = getSelectedDates.selectedDates;
      let today = new Date().toLocaleDateString();
      let selDateDay = new Date(selectedDates).toLocaleDateString();
      if (selDateDay === today) {
        this.weekDay = this.$t("today");
      } else {
        this.weekDay = getWeekDay(selectedDates[0]);
      }
      this.showMarker();
      this.draftDate(selectedDates);
    },
    draftDate: function (date) {
      this.month = date.toLocaleString("default", { month: "long" });
      this.day = new Date(date).toLocaleDateString().split(".")[0];
    },
    request() {
      if (!this.widget.query?.request) return;
      this.tasks = api
        .request({
          name: "getTasksByRQL",
          pathParam: this.node.id,
          payload: {
            rql: this.widget.query.request,
            sort_type: this.widget.query.sort_type,
          },
        })
        .then(({ data }) => {
          this.items = data.data.map((item) => {
            return TreeService.treeFromDictToDictFlat(item);
          });
          this.showMarker();
        });
    },
  },
  mounted() {
    this.request();
    this.draftDate(new Date());
  },
  watch: {
    sidebarTaskList: function (val, oldVal) {
      if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
        this.showMarker();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.containerFlatPicker {
  display: flex;
}

.containerDate {
  width: 100%;
  height: 354px;
  background: #343957;
  color: white;

  text-align: left;
  padding: 29px 16px;
  box-sizing: border-box;

  position: relative;
}

.containerTask {
  padding: 0 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;

  li {
    cursor: pointer;
    margin-left: 14px;

    &:hover {
      text-decoration: underline;
    }

    &::marker {
      color: #6b51df;
    }
  }
}

.weekDay {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  margin: 0;
  padding: 0;
}

.dayMonth {
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
}

.widget-calendar-link {
  width: fit-content;
}
</style>
