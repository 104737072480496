<template>
  <canvas :id="id" width="1000" height="500"></canvas>
</template>

<script>
import api from "@/api";
import { format } from "@/utils/date";
import { mapState } from "vuex";
// import { Autoreload } from "@/utils/autoreload";

import {
  BarController,
  BarElement,
  Chart,
  Legend,
  Title,
  Tooltip,
} from "chart.js";

Chart.register(BarElement, BarController, Legend, Title, Tooltip);

function formatter(items) {
  const result = [];

  items.forEach((item) => {
    const date = item.created_at;
    const dateFormatted = format(date, "MMM");

    if (!result.length) {
      result.push({ x: dateFormatted, y: 1 });
    } else {
      const lastElement = result[result.length - 1];
      if (lastElement.x === dateFormatted) {
        result[result.length - 1].y++;
      } else {
        result.push({ x: dateFormatted, y: 1 });
      }
    }
  });

  return result;
}

const config = {
  type: "bar",
  data: {
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [
          "rgba(108, 81, 223, 0.5)",
          "rgba(108, 81, 223, 0.6)",
          "rgba(108, 81, 223, 0.7)",
          "rgba(108, 81, 223, 8.8)",
          "rgba(108, 81, 223, 0.9)",
          "rgba(108, 81, 223, 1)",
        ],
        borderColor: ["rgba(108, 81, 223, 1)"],
        borderWidth: 1,
      },
    ],
  },
  options: {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  },
};

export default {
  props: {
    widget: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      id: "cr_" + Date.now(),
      config: Object.assign({}, config),
    };
  },
  computed: {
    ...mapState("core$common", ["node"]),
  },
  methods: {
    request() {
      if (!this.widget.query?.request) return;

      api
        .request({
          name: "getTasksByRQL",
          pathParam: this.node.id,
          payload: {
            rql: this.widget.query.request,
            fields: this.widget.query.fields,
            sort_type: this.widget.query.sort_type,
          },
        })
        .then(({ data }) => {
          this.config.data.datasets[0].label = this.widget.title;
          this.config.data.datasets[0].data = formatter(data.data);
          this.initChart();
        });
    },
    initChart() {
      this._ctx = document.getElementById(this.id).getContext("2d");
      this._chart = new Chart(this._ctx, config);
    },
  },
  created() {
    this.request();
    // this._autoreload = new Autoreload(this.request.bind(this));
    // this._autoreload.init();
  },
  // beforeDestroy() {
  //   this._autoreload.destroy();
  // },
};
</script>

<style lang="scss" scoped></style>
