<script>
export default {
  props: {
    imgSrc: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
  },
};
</script>

<template>
  <div class="calendarfull-user">
    <v-avatar size="32">
      <v-img v-if="imgSrc" :src="imgSrc"></v-img>
      <span v-else-if="title.length">{{ title[0] }}</span>
    </v-avatar>
    <div class="calendarfull-user-container">
      <p class="calendarfull-user-title" :title="title">{{ title }}</p>
      <p class="calendarfull-user-subtitle" :title="subtitle">{{ subtitle }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
