<template>
  <span> {{ getCell }} </span>
</template>

<script>
import { mapState } from "vuex";
import tableCellsMixin from "./tableCellsMixin";

export default {
  mixins: [tableCellsMixin],
  computed: {
    ...mapState("core$common", ["users"]),
    getCell() {
      const userId = this.item[`${this.header}.id`];
      const user = this.users.find((user) => user.id === userId);
      if (!user) return "";

      return user._fullname || "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
