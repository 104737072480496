export const debounce = (f, ms = 400) => {
  let timeout = null;
  return function () {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    timeout = setTimeout(() => f.apply(this, arguments), ms);
  };
};
